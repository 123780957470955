// @ts-nocheck
import { Box, Flex } from "@chakra-ui/react";
import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/youtube";

import styled from "styled-components";
//import dynamic from "next/dynamic";
//import ReactPlayer from "react-player/youtube";

import FlexDisplayItem from "../../atoms/FlexDisplayItem";

const StyledReactPlayer = styled(ReactPlayer)`
  > div {
    position: relative;
    aspect-ratio: 16/9;
  }
`;

export const VideoResponsive = styled.div`
  width: 100%;
  max-width: 800px;
  height: auto;
  display: flex;
  object-fit: cover;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  justify-content: center;
`;

export const VideoIframe = styled.iframe`
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const MaxWidth = styled.div`
  width: 100%;
  align-self: center;

  max-width: 800px;
  display: flex;
  height: 100%;
`;

const pushVideoEventToDataLayer = (video, gtmVideoStatus) => {
  dataLayer.push({
    event: "gtm.video",
    "gtm.videoStatus": gtmVideoStatus,
    "gtm.videoProvider": "YouTube",
    "gtm.videoTitle": video.title,
    "gtm.videoUrl": video.src,
  });
};

const VideoTopic = (data: any) => {
  const [ytval, setYtval] = useState("");
  // console.log("VideoTopic", JSON.stringify(data));
  const {
    youtubeUrl,
    id,
    flexDisplayHorizontalAlignment,
    flexDisplayVerticalAlignment,
    fw,
  } = data;

  const refIframe = useRef<HTMLIFrameElement>(null);

  // React.useEffect(() => {
  //   console.log("test");
  //   refIframe.current.addEventListener("play", () => {
  //     console.log("play");
  //     pushVideoEventToDataLayer(refIframe, "start");
  //   });
  //   refIframe.current.addEventListener("pause", () => {
  //     console.log("pause");
  //     pushVideoEventToDataLayer(refIframe, "pause");
  //   });
  //   refIframe.current.addEventListener("ended", () => {
  //     console.log("ended");
  //     pushVideoEventToDataLayer(refIframe, "complete");
  //   });
  // }, [refIframe]);

  useEffect(() => {
    console.log("test");
    const ytVal = youtubeUrl.split("embed/")[1];

    setYtval(ytVal);
  }, [youtubeUrl]);

  return (
    <FlexDisplayItem
      data-layoutid={data["data-layoutid"]}
      fh={flexDisplayHorizontalAlignment || "flex-start"}
      fv={flexDisplayVerticalAlignment || "flex-start"}
      fw={fw}
      data-cid={data.id}
    >
      {/*  <VideoResponsive>
          <VideoIframe
            ref={refIframe}
            data-testid="video-embed"
            // enablejsapi="true"
            width="100%"
            height="100%"
            srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${ytval}/?enablejsapi=1&autoplay=1><img src=https://img.youtube.com/vi/${ytval}/hqdefault.jpg alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            // webkitallowfullscreen="true"
            // mozallowfullscreen="true"
            title="Embedded youtube"
      />*/}
      <Flex
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Box width="100%" maxW="640px">
          {/*<YouTube
            videoId={ytval}
            //width="100%"
            //height="100%"
            //srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${ytval}/?enablejsapi=1&autoplay=1><img src=https://img.youtube.com/vi/${ytval}/hqdefault.jpg alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>`}
            opts={{
              controls: 1,
            }}
          />*/}
          {ytval ? (
            <StyledReactPlayer
              data-testid="video-embed"
              light={
                <Image
                  src={`https://img.youtube.com/vi/${ytval}/maxresdefault.jpg`}
                  alt="YouTube video thumbnail"
                  layout="fill"
                  objectFit="cover"
                />
              }
              playing
              controls
              width="100%"
              height="100%"
              url={`https://www.youtube.com/watch?v=${ytval}`}
              config={{
                youtube: {
                  playerVars: {
                    rel: 0,
                    showinfo: 0,
                    modestbranding: 1,
                    fs: 0,
                    disablekb: 1,
                    host: "http://www.youtube.com",
                  },
                },
              }}
            />
          ) : null}
        </Box>
      </Flex>
      {/*</VideoResponsive>*/}
    </FlexDisplayItem>
  );
};
{
  /* </VideoResponsive>
      <FlexDisplayItem
      data-layoutid={data["data-layoutid"]}
      fh={flexDisplayHorizontalAlignment || "center"}
      fv={flexDisplayVerticalAlignment || "center"}
      fw={fw}
      data-cid={data.id}
    >
    </FlexDisplayItem> */
}
// <ReactPlayer url={youtubeUrl} />

// {isSSR ? (
// <ReactPlayerWrapper url={youtubeUrl} width="100%" height="100%" />
// ) : null}

// const fields = data.image.fields;
// const { title, file, description, fw, layout } = fields;
// const {
//   flexDisplayVerticalAlignment,
//   flexDisplayHorizontalAlignment,
//   width,
//   height,
// } = data;
// // TODO: add layout, intrinsic, responsive, fixed, fill, etc.
// return (
//   <FlexDisplayItem
//     data-layoutid={data["data-layoutid"]}
//     fh={flexDisplayHorizontalAlignment || "flex-start"}
//     fv={flexDisplayVerticalAlignment || "flex-start"}
//     fw={fw}
//     data-cid={data.id}
//   >
//     <Image
//       src={`https:${file.url}`}
//       alt={title}
//       title={description}
//       layout={layout || "intrinsic"}
//       width={width || file?.details?.image?.width}
//       height={height || file?.details?.image?.height}
//     />
//   </FlexDisplayItem>
// ); // 130x49 130x33

export default React.memo(VideoTopic);
